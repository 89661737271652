.loader-container {
  z-index: 1055;
  text-align: center;
  position: absolute;
  top: 100px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loader-hidden {
  display: none;
}

.download-progress-elems {
  width: max-content;
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
}
/*# sourceMappingURL=composition.95df64d9.css.map */
