.loader-container {
  position: absolute;
  left: 50%;
  top: 100px;
  z-index: 1055;
  transform: translate(-50%, -50%);
  text-align: center;
}
.loader-hidden {
  display: none; 
}

.download-progress-elems {
  position: absolute;
  left: 50%;
  top: 80%;
  transform: translate(-50%, -50%);
  width:max-content;
}